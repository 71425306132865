<template>
  <div>
    <div class="navMenu-main">
      <div id="menu" class="gn-icon-menu"><span></span></div>
    </div>
    <Header :instanceInfo="instanceInfo" />
    <div class="main">
      <div class="container">
        <div class="row">
          <SideMenu :instanceInfo="instanceInfo" />
          <div class="col-md-9">

<div class="in-out-box">
    <ul class="squarebuttons">
        <li :class="{ 'active': currentPath == '/messages/inbox' }">
          <router-link to="/messages/inbox">
            <i class="inbox"></i>
            Inbox
          </router-link>
        </li>
        <li :class="{ 'active': currentPath == '/messages/outbox' }">
          <router-link to="/messages/outbox">
            <i class="outbox"></i>
            Outbox
          </router-link>
        </li>
<li :class="{ 'active': currentPath == '/messages/conversations' }">
          <router-link to="/messages/conversations">
            <i class="conversations"></i>
            Conversations
          </router-link>
        </li>
  </ul>
</div>

<h2 class="pink">My mailbox</h2>
                  <br><br><br>
							<div class="shadow padded">
		                    	<ul class="flirt-list">

                            <li v-for="message in messages" :key="message.uuid">
                              <div class="fl2">
				                            		<router-link :to="'/messages/conversations/' + message.conversation">
				                            			<img class="chat-profile-photo rounded" :src="'/uploads/150/' + message.memberToDto.image">
				                            		</router-link>
				                            	</div>
				                          		<div class="fl3">
				                                	<p class="username">
				                                		<router-link :to="'/messages/conversations/' + message.conversation">
				                                			{{ message.memberToDto.name }}
				                                		</router-link>
				                                	</p>
				                                    <p class="time">
				                                    	{{ formatDate(message.createdAt) }}
				                                    </p>
				                                    <p class="location">
				                                    					                                    </p>
				                                </div>
				                                <div class="fl4">
				                                															{{ message.body }}
																	                                </div>
                                                                  <div class="fl5 mobile-hide">
				                                						                                	<router-link v-if="message.attachmentDto" :to="'/messages/conversations/' + message.conversation">
					                                		<img src="/theme/001/images/icon_attachment.png">
					                                	</router-link>
				                                					                                </div>
				                                <div class="fl6">
				                                					                                </div>
				                                <div class="fl7">
				                                	<router-link class="btn mobile-hide" :to="'/messages/conversations/' + message.conversation">
				                                		View
				                                	</router-link>
				                                	<router-link class="mobile-only" :to="'/messages/conversations/' + message.conversation">
			                                			View
			                                		</router-link>
				                                </div>
                            </li>

		                        </ul>

		                    </div>

		                    <div class="row">
		                        <div class="col-md-12">
                              <div class="pager center">
                <div class="pagination">
                  <ul>
                    <li :class="{ 'disabled': currentPage == 1 }">
                      <router-link v-if="currentPage > 1" :to="'/messages/outbox?page=' + (currentPage - 1)"
                        rel="prev">&laquo;</router-link>
                      <span v-else>&laquo;</span>
                    </li>
                    <li v-for="n in pagesCount" :key="n" :class="{ 'active': n == currentPage }">
                      <router-link v-if="n != currentPage" :to="'/messages/outbox?page=' + n">{{ n }}</router-link>
                      <span v-else>{{ n }}</span>
                    </li>
                    <li :class="{ 'disabled': currentPage == pagesCount }">
                      <router-link v-if="currentPage < pagesCount" :to="'/messages/outbox?page=' + (currentPage + 1)"
                        rel="next">&raquo;</router-link>
                      <span v-else>&raquo;</span>
                    </li>
                  </ul>
                </div>
              </div>
		                        </div>
		                        </div>



</div>
      </div>
    </div>
  </div>
  <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
</div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';
import SideMenu from '@/app/components/SideMenu';
import { useUrl } from '@/app/router';
import { fetchOutbox } from '@/app/api/message';
import { useQuery } from '@/app/use/router';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  name: 'Outbox',
  components: { Header, Footer, SideMenu },
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
    companyName: {
      type: String,
      required: false,
    },
  },
  setup() {
    const { currentPath } = useUrl();

    const route = useRoute();
    const { pagination } = useQuery();
    const messages = ref([]);
    const totalItems = ref(0);
    const pagesCount = ref(1);
    const currentPage = ref(1);

    const reloadEmojiScript = function () {
        document.getElementById('emoji-script')?.remove();
        const emojiPlugin = document.createElement('script');
        emojiPlugin.src = '/global/emoji/jquery.emoji.js';
        emojiPlugin.id = 'emoji-script';
        emojiPlugin.async = true;
        document.body.appendChild(emojiPlugin);
    };

    const loadMessages = () => {
      pagination.value.rowsPerPage = 10;
      fetchOutbox({
        pagination: pagination.value,
      })
        .then(async data => {
          messages.value = data.items;
          totalItems.value = data.total;
          pagesCount.value = data.pagesCount;
          currentPage.value = data.page;
          reloadEmojiScript();
        });
    };

    loadMessages();

    watch(
      () => route.query.page,
      async () => {
        loadMessages();
      },
      { immediate: true }
    );

    const formatDate = (dateString) => {
      const date = new Date(Date.parse(dateString));
      if (date > moment().startOf('day')) {
        return moment(date).fromNow();
      } else if (date.getFullYear() == new Date().getFullYear()) {
        return moment(date).format("Do MMMM HH:mm");
      } else {
        return moment(date).format("Do MMMM YYYY HH:mm");
      }
    };

    return {
      currentPath,
      messages,
      totalItems,
      pagesCount,
      currentPage,
      pagination,
      formatDate
    };
  },
};
</script>
